jQuery(function($){

		/**
		 * Code fired on each page
		 *
		 *	@author Przemysław Hernik
		 *	@date 03.02.2017
		 *	@see https://toddmotto.com/mastering-the-module-pattern/
		 */

		initJS = (function(){

			var

				init = function(){
					initNavPin();
					_initCommentsScripts();
					_initAdditional();
					_initNavs();
					_initMobileMenu();
					_initSearchDesktop();
					_initPopup();
					_initStickySidebar();
					_initNoSleep();
					_initBackToTopBtn();
					_InitSwiperRecipe();
					_initClickableDropdownParent();
					_InitPrintPlugin();
					_InitCustomPlay();
					_InitPostBoostScript();
					_InitLightboxProductImg();
					_InitGtagEvents();
				},

				finalize = function(){

				},
				

				/**
				 * Add Gtags
				 */
				_InitGtagEvents = function(){

					$( document ).on( 'click', '.entry-share-btns', function( event ) { 
						event.preventDefault( ); 

						recipeurl = window.location.href;

						gtag('event', 'Kliknięcie - strona przepisu - udostępnij', { 
							'event_label': recipeurl,  
							'event_category': 'UX', 
							'event_action': 'share', 
						});
						// console.log( 'Event click social recipe url: ' + recipeurl );

					});


					$( document ).on( 'click', '#menu-item-234 a', function( event ) { 
						// event.preventDefault( ); 
						gtag('event', 'Kliknięcie - ikona Facebook w nagłówku', { 
							'event_label': 'facebook-header',  
							'event_category': 'icon', 
							'event_action': 'click', 
						});
						// console.log( 'Kliknięcie - ikona Facebook w nagłówku' );

					});


					$( document ).on( 'click', '#menu-item-366 a', function( event ) { 
						// event.preventDefault( ); 
						gtag('event', 'Kliknięcie - ikona Facebook w stopce', { 
							'event_label': 'facebook-footer',  
							'event_category': 'icon', 
							'event_action': 'click', 
						});
						// console.log( 'Kliknięcie - ikona Facebook w stopce' );

					});

					$( document ).on( 'click', '#menu-item-18 a', function( event ) { 
						// event.preventDefault( );
						gtag('event', 'Kliknięcie - ikona YouTube w nagłówku', { 
							'event_label': 'youtube-header',  
							'event_category': 'icon', 
							'event_action': 'click', 
						});
						// console.log( 'Kliknięcie - ikona YouTube w nagłówku' );

					});


					$( document ).on( 'click', '#menu-item-367 a', function( event ) { 
						// event.preventDefault( ); 
						gtag('event', 'Kliknięcie - ikona YouTube w stopce', { 
							'event_label': 'youtube-footer',  
							'event_category': 'icon', 
							'event_action': 'click', 
						});
						// console.log( 'Kliknięcie - ikona YouTube w stopce' );

					});
				},

				/**
				 * Make Parent div clickable to run print plugin
				 */
				_InitLightboxProductImg = function(){

					$( '#lightbox_img' ).click( function( event ){
						event.preventDefault();
						var content = $( '.modal-body' );
						content.empty( );
						content.html( $( this ).html() );
						$( ".modal-product-img" ).modal({
							show:true
						});
					});
				},

				/**
				 * Make Parent div clickable to run print plugin
				 */
				_InitPrintPlugin = function(){

					$(".printfriendly .noslimstat").attr( "onclick","window.print();gtag('event', 'Kliknięcie - strona przepisu - drukuj/pdf', {'event_label': window.location.href,'event_category': 'UX','event_action': 'print'}); return false;");
					
					$( document ).on( 'click', '.ai__item--print', function( event ) { 
						event.preventDefault( );
						$( ".printfriendly .noslimstat" ).click( ); 

					});

				},

				/**
				 * Custom button play on youtube embeded
				 */
				_InitCustomPlay = function(){
					$('#mis_play').on('click', function( event ) {
						$( this ).delay( 900 ).fadeOut( 'slow' );
						$("#mis_video_embeded")[0].src += "&autoplay=1";
						event.preventDefault();
					});
				},

				/**
				 * Change url when scroll down on posts
				 */
				_InitPostBoostScript = function(){

					var changeUrl = false

					function startCheckNewUrl() {
	
						var currentTopPosition = 0;
						var reloadNamePoint = [];
						var collection = document.getElementsByClassName( "scroll-recipes" );

						for ( i = 0; i < collection.length; i++ ) {

							var offset	= collection[ i ].offsetTop;
							var slug 	= '/' + collection[ i ].getAttribute( 'slug' );
							var title 	= collection[ i ].getAttribute( 'tag_title' );
							
							reloadNamePoint.push( {
								top		: offset,
								slug 	: slug, 
								title 	: title
							} );

						}

						for ( var i = 0; i < reloadNamePoint.length; i++ ) {
							currentTopPosition = ( window.scrollY + ( window.innerHeight / 2 ) );

							if (reloadNamePoint.length > 1) {
								if (reloadNamePoint[1].top < currentTopPosition) {
									changeUrl = true
								}
							}

							if ( ( ( i + 1 == reloadNamePoint.length ) || ( reloadNamePoint[i + 1].top > currentTopPosition ) ) && changeUrl )  {
								// console.log( 'art:' + i + ' | art.length: ' + reloadNamePoint.length + ' | Next point: ' + reloadNamePoint[i + 1] + ' | Current top:' + currentTopPosition );
								
								if ( reloadNamePoint[i].slug != window.location.pathname ) {

									window.history.pushState( null, reloadNamePoint[i].title, reloadNamePoint[i].slug );
									ga('set', 'page', reloadNamePoint[i].slug );
									ga('send', 'pageview');

									gtag( 'config', 'UA-123338272-1', {
										'page_title' : reloadNamePoint[i].title,
										'page_path': reloadNamePoint[i].slug
									} );

								}else if ( ( i == reloadNamePoint.length ) ) {
									window.history.pushState( null, reloadNamePoint[0].title, reloadNamePoint[0].slug );
								}

								break;
							}
						}

					}

					function refreshStarRatingJS() {
						var src = 'comment-rating-field-pro-plugin/assets/js/min/frontend-min.js';

						if ( $( 'script[src*="' + src + '"]' ).length != 0 ) {
							var starscript  = $( 'script[src*="comment-rating-field-pro-plugin/assets/js/min/frontend-min.js"]' ).attr( 'src' );

							$( 'script[src*="' + starscript + '"]' ).remove( );
							$( '<script/>' ).attr( 'src', starscript ).appendTo( 'body' );
						}

					}

					setInterval( startCheckNewUrl , 1000 );
					setInterval( refreshStarRatingJS , 3000 );

				},

				_initBackToTopBtn = function(){
					$( window ).scroll( function() {
						if ( $( this ).scrollTop() >= 50 ) {
							$( '#back-to-top' ).addClass( 'active' );
						} else {
							$( '#back-to-top' ).removeClass( 'active' );
						}
					});

					$( '#back-to-top' ).click(function() {
						$( 'body,html' ).animate({
							scrollTop : 0
						}, 500);

						if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
							$("html, body").animate({
								scrollTop: 0
							}, 500);
							return false;
					    } 
					});

				},
				/**
				 * Make dropdown parent clickable
				 *	By default BS4 blocks links which are dropdown parent.
				 */
				_initClickableDropdownParent = function(){
					$("a.dropdown-toggle").click(function(){

						// $( this ).parent().find( '.dropdown-menu' ).slideToggle( 'normal' ); 
						window.location.href = $(this).attr('href');
					});
				},

				_initCommentsScripts = function(){

					$('body').on('click', '.comment-send', function() {
						$( this ).next().slideDown( 'slow' ); 
					//   $("#send-comments").css("display", "none");
					});

					$(".go-to-comments").click(function() {
						$('html,body').animate({
							scrollTop: $("ul.comments_list").offset().top - 120},
							'slow');
					});

					$("#send-button").click(function(){
						$("#send-photo").slideDown("slow");
						$("#send-button").css("display", "none");
					});

				},

				_initAdditional = function() {

					/** Position for left Sidebar if banner exist **/
					var h = $('#main.with-banner .post-top').outerHeight(true);
					$('#main.with-banner #left-sidebar').css("top", h);

					/** Add class for sidebar latest posts **/

					$('.widget-latest-post .latest-post').each(function(i, obj) {
						$(this).addClass('article__tile');
					});

					/** Read more category description on mobile **/

					$( '.category-description .description-more' ).click( function(){
						$( this ).hide();
						$( this ).parents( '.category-description' ).find( '.inner' ).addClass( 'open' );
						$( this ).parents( '.category-description' ).find( '.inner' ).animate({height:'100%'}, 300);
					});

				},

				_initNavs = function(){
					/* Search tobar */
					$( "#topbar .topbar__search" ).click( function() {
						$(".full__search").addClass("active");

									setTimeout(function(){
								  $( '#search-field-popup' ).focus();
									}, 1000 );
					});

					$(".full__search .search__close").click(function(){
						$(".full__search").removeClass("active");
					});

					$(".topbar__menu .topbar__label.mobile").click(function(){

						if( $(this).hasClass("open") ) {
							$(this).removeClass("open");
							$("#topbar__menu").slideUp();
						} else {
							$(this).addClass("open");
							$("#topbar__menu").slideDown();
						}

					});

					$(".sub__menu--main li").click(function(){
						$('.sub__menu--main li .sub__menu--child').removeClass('visible');
						$(this).children('.sub__menu--child').addClass('visible');
					});

					$(".menu-recipe").click(function(){

						if( !$('.header__dropdown').hasClass('visible') ) {
							$(".header__dropdown").addClass('visible');
						} else {
							$(".header__dropdown").removeClass('visible');
						}
					});

					$(".menu-product").click(function(){
						
						if( !$('.products__dropdown').hasClass('visible') ) {
							$(".products__dropdown").addClass('visible');
						} else {
							$(".products__dropdown").removeClass('visible');
						}
					});

/* Disable JS - menu is visible by CSS
					if (window.matchMedia('(max-width: 1030px)').matches) {

						$(".menu-recipe").on('click',function(){
							if( $(this).hasClass('open') ) {
								$(this).removeClass('open');
								$(".recipes__dropdown").removeClass('visible');
								$('.recipes__dropdown .dropdown__menu').removeClass('active');
							} else {
								$(".recipes__dropdown").addClass('visible');
								$(this).addClass('open');
							}
						});

						$(".menu-product").on('click',function(){
							if( $(this).hasClass('open') ) {
								$(this).removeClass('open');
								$(".products__dropdown").removeClass('visible');
								$('.products__dropdown .dropdown__menu').removeClass('active');
							} else {
								$(".products__dropdown").addClass('visible');
								$(this).addClass('open');
							}
						});

					}
					if (window.matchMedia('(min-width: 1031px)').matches) {

						$(".menu-recipe").on('mouseenter',function(){		
							$(".recipes__dropdown").addClass( 'visible' );
							$( this ).addClass('open');
							$( this ).on("click", function ( event ) {
								window.location.href = $( this ).find( 'a' ).attr( 'href' );
							});	
								
						});

						$(".menu-recipe").on('mouseleave',function(){
							$(".recipes__dropdown").removeClass('visible');
							$(".menu-recipe").removeClass('open');
						});

						$(".recipes__dropdown").on('mouseenter',function(){
							$(".recipes__dropdown").addClass('visible');
							$(".menu-recipe").addClass('open');
						});

						$(".recipes__dropdown").on('mouseleave',function(){
							$(".recipes__dropdown").removeClass('visible');
							$('.recipes__dropdown .dropdown__menu').removeClass('active');
							$(".menu-recipe").removeClass('open');
						});
	

						$(".menu-product").on('mouseenter',function(){
							$(".products__dropdown").addClass('visible');
							$( this ).addClass('open');
							$( this ).on("click", function ( event ) {
								window.location.href = $( this ).find( 'a' ).attr( 'href' );
							});	
						});

						$(".menu-product").on('mouseleave',function(){
							$(".products__dropdown").removeClass('visible');
							$(".menu-product").removeClass('open');
						});

						$(".products__dropdown").on('mouseenter',function(){
							$(".products__dropdown").addClass('visible');
							$(".menu-product").addClass('open');
						});

						$(".products__dropdown").on('mouseleave',function(){
							$(".products__dropdown").removeClass('visible');
							$('.products__dropdown .dropdown__menu').removeClass('active');
							$(".menu-product").removeClass('open');
						});


					}
*/					
					/** TOPBAR fixed **/

							var lastScrollTop = 0;
							var headerHeightSize = $('.header-bar').height();

							$(window).scroll(function(event){
							   var currentTop = $(this).scrollTop();

								if (currentTop < lastScrollTop) {
									//scroll up

									if( currentTop < headerHeightSize ) {
										$('#topbar').removeClass('fixed-animation');
									}

									if (currentTop < headerHeightSize) {
										$('#topbar').removeClass('fixed');
									}

									if (currentTop < headerHeightSize + 80 ) {
										$('#topbar').addClass('hide-up');
									}

									if (currentTop < headerHeightSize - 20) {
										$('#topbar').removeClass('hide-up');
									}

								}

								if (currentTop > lastScrollTop) {
									//scroll down
									$('#topbar').removeClass('hide-up');

									if( currentTop > 80 ) {
										$('#topbar').addClass('fixed-animation');
									}

									if (currentTop > headerHeightSize) {
										$('#topbar').addClass('fixed');
									}

								}
							lastScrollTop = currentTop;

							/** Notification cookies **/
							$('.notification-bar .nb__close').click(function(){
								$('.notification-bar').addClass('hidden');
								$('.notification-bar').removeClass('active');

								Cookies.set('notification', '1', { expires: 1 });
							});

							var cookies = Cookies.get('notification');

							if( cookies != 1 ) {
								if( ! $('.notification-bar').hasClass('hidden') ) {

									if( $('#topbar').hasClass('fixed') ) {
										$('.notification-bar').addClass('active');
									} else {
										$('.notification-bar').removeClass('active');
									}

								}
							}
							/** Notification cookies end **/
						  });
						/** TOPBAR fixed end **/

						/** Bottom notification **/
						$( '.infobar-bottom .btn' ).click( function() {
							$( this ).parents( '.infobar-bottom' ).addClass( 'hidden' );
							Cookies.set( 'bottomNotification', '1', { expires: 365 } );
						});

						var bottomNotificationCookies = Cookies.get( 'bottomNotification' );
						if ( bottomNotificationCookies != 1 ) {
							$( '.infobar-bottom' ).addClass( 'active' );
						}
						/** Bottom notification end **/

				},

				_initMobileMenu = function(){

					$(".mobile__header .navbar-toggle").click(function(){

						if($("#mobile-navbar").hasClass("open") ) {
							$("#mobile-navbar").removeClass("open");
							$("#mobile-navbar").slideUp();
							if($(".mobile__header li.menu-item-has-children").hasClass("active") ) {
							  $(".mobile__header li.menu-item-has-children").removeClass("active");
							  // $(".mobile__header li.menu-item-has-children").children("ul.sub-menu").slideUp();
							  // $(".mobile__header li").slideDown();
							}
						}

						else {
							$("#mobile-navbar").addClass("open");
							$("#mobile-navbar").slideDown();
							if($("#mobile-search").hasClass("search-open") ) {
							  $("#mobile-search i").addClass("glyphicon-search");
							  $("#mobile-search i").removeClass('glyphicon-remove');
							  $("#mobile-search").removeClass("search-open");
							  $("#mobile-search-content").slideUp("slow");
							}
						}

					});


					$("#mobile-search").click(function(){

						$(".full__search").addClass("active");

						setTimeout(function(){
							$( '#search-field-popup' ).focus();
						}, 1000 );

					});

					$( '#menu-mobile-menu >.menu-item-depth-0 > a' ).click( function() {
						$( this ).parent().toggleClass( 'active' );
						$( this ).next().slideToggle( 'normal' ); 
					});

					$( '#menu-mobile-menu li.menu-item-depth-1 > a' ).click( function() {
						$( this ).parent().toggleClass( 'active' );
						$( this ).next().slideToggle( 'normal' );
					});

					$( '#menu-mobile-menu li.menu-item-depth-2 > a' ).click( function() {
						$( this ).parent().toggleClass( 'active' );
						$( this ).next().slideToggle( 'normal' );
					});

				},


				_initSearchDesktop = function(){

					$("#desktop-search").click(function(){

						$( ".desk-search-contain" ).slideToggle( "slow" );

					});
				},

				_initPopup = function(){
					var random = Math.floor(Math.random() * $('.newsletter-popup .np__content').length);
					$('.newsletter-popup .np__content').hide().eq(random).show();

					$(".newsletter-open").click(function() {
						$('.newsletter-popup').fadeIn('1000');
					});

					$(".np__close").click(function() {
						$('.newsletter-popup').fadeOut('1000');
					});

				},


				_initStickySidebar = function(){
					if ($(window).width() > 767) {
						function stickybanners() {
						  var banner = $('.sidebar-fixed');

						  if (banner.length) {
							var containerOffsetTop = $( '.left-container' ).offset().top,
								containerHeight = $( '.left-container' ).outerHeight(),
									sidebarOffsetTop = $('.sidebar-fixed').offset().top - parseFloat($('.sidebar-fixed ').css('marginTop').replace(/auto/, 0)),
								maxY = containerHeight + containerOffsetTop - $('.sidebar-fixed').outerHeight() - 100;

							$(window).scroll(function(evt) {
								var y = $(this).scrollTop();
								if (y > sidebarOffsetTop) {
									if (y < maxY) {
										$('.sidebar-fixed ').addClass('fixed').removeAttr('style');
									} else {
										$('.sidebar-fixed').removeClass('fixed').css({
											position: 'absolute',
											sidebarOffsetTop: (maxY + sidebarOffsetTop) + 'px'
										});
									}
								} else {
									$('.sidebar-fixed').removeClass('fixed');
								}
							});
						  }

						}

						setTimeout(stickybanners, 2000);
					}

				},

				_initNoSleep = function(){
					var noSleep = new NoSleep();

					function enableNoSleep() {
					  noSleep.enable();
					  document.removeEventListener('click', enableNoSleep, false);
					}

					// Enable wake lock.
					// (must be wrapped in a user input event handler e.g. a mouse or touch handler)
					document.addEventListener('click', enableNoSleep, false);
				},

				initNavPin = function(){
					if ($(window).width() > 1170) {

						var headerHeight = $('#main_navigation').height();

						$(window).on('scroll', {
								previousTop: 0
						},

						function() {
							var currentTop = $(window).scrollTop();
							//check if user is scrolling up
							if (currentTop < this.previousTop) {
								//if scrolling up...
								if (currentTop > 0 && $('.navbar-custom').hasClass('is-fixed')) {
									$('.navbar-custom').addClass('is-visible');
								} else {
									$('.navbar-custom').removeClass('is-visible is-fixed');
								}
							} else if (currentTop > this.previousTop) {
								//if scrolling down...
								$('.navbar-custom').removeClass('is-visible');
								if (currentTop > headerHeight && !$('.navbar-custom').hasClass('is-fixed')) $('.navbar-custom').addClass('is-fixed');
							}
							this.previousTop = currentTop;
						});
					}
				},


				_InitSwiperRecipe = function(){
					var swiper = new Swiper('#recipe_slider', {
						slidesPerView: 3,
						slidesPerGroup: 3,
						spaceBetween: 30,
						loop: true,
						loopFillGroupWithBlank: true,
						navigation: {
							nextEl: '.swiper-button-next',
							prevEl: '.swiper-button-prev',
						},
						autoplay: {
							delay: 5000,
						},
						pagination: {
							el: '.swiper-pagination',
						},
						breakpoints: {
							992: {
								slidesPerView: 3,
								slidesPerGroup: 3,
								spaceBetween: 30,
							},
							768: {
								slidesPerView: 2,
								slidesPerGroup: 2,
								spaceBetween: 20,
							},
							599: {
								slidesPerView: 1,
								slidesPerGroup: 1,
								spaceBetween: 10,
							}
						}

					});
				}

			return {
				init: init,
				finalize: finalize
			};

		})();

});
