jQuery(function($){

	/**
	 * Code fired on homepage
	 *
	 *	@author Przemysław Hernik
	 *	@date 03.02.2017
	 *	@see https://toddmotto.com/mastering-the-module-pattern/
	 */

	homeJS = (function(){

		var

			_hello = 'I\'m homeJS module!',

			init = function(){
				consoleLoaded();
				_InitScrollbars();
				_InitSwiperSlider();
			},

			finalize = function(){

			},

			_InitScrollbars = function(){

				$(".latest__posts .scroll-inner").mCustomScrollbar({
					theme:"minimal"
				});

				$(".home__scroll-section").mCustomScrollbar({
					axis:"x",
					theme:"light-3",
					advanced:{autoExpandHorizontalScroll:true}
				}); 
			},


			consoleLoaded = function(){
				console.log(_hello);
			},

			_InitSwiperSlider = function(){
				var swiper = new Swiper('#slider-1', {
					loop: true,
					loopFillGroupWithBlank: true,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					autoplay: {
						delay: 8000,
					},
					pagination: {
						el: '.swiper-pagination',
					},
				});
			}
		;

		return {
			init: init,
			finalize: finalize
		};

	})();

});